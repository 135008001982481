import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Modal from "../components/Modal/Modal"
import CostumeCard from "../components/CostumeCard/SingleCostumeCard"
import LoadingImage from "../images/loading.svg"
import NoResults from "../images/noResults.svg"
import * as queryString from "query-string"

import { navigate } from "gatsby"

const Costumes = ({ location }) => {
  const {
    id,
    name,
    color,
    size,
    recordid,
    pictureTime,
    reservation,
    paperformurl,
  } = queryString.parse(location.search)

  const [loading, setLoading] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalImage, setModalImage] = useState("")
  const [error, setError] = useState(false)
  const [costume, setCostume] = useState([])

  const getCostume = async () => {
    try {
      const res = await fetch(`/.netlify/functions/getCostumeById?id=${id}`)
      const data = await res.json()
      setCostume(data.costume)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setError(true)
    }
  }

  useEffect(() => {
    if (localStorage.getItem("internal") === null) {
      getCostume()
    } else {
      navigate(`/internal-check?name=${name}&id=${recordid}`)
    }
  }, [])

  return (
    <Layout>
      {showModal && (
        <Modal close={() => setShowModal(false)}>
          <div className="p-5 flex justify-center items-start">
            <img
              className="max-w-sm max-h-screen object-contain"
              src={modalImage}
            />
          </div>
        </Modal>
      )}

      <Seo title="Costumes" />
      <div className="container py-2 px-1 mx-auto min-h-full">
        <div className="mt-8 mb-4 md:my-2 mx-2">
          <h1 className="text-3xl md:text-6xl text-gray-800 font-bold ">
            {name}
          </h1>
          <p className="text-lg md:text-xl text-gray-800 mt-2">
            <span className="font-bold">Size:</span> {size}
          </p>
          <p className="text-lg md:text-xl text-gray-800">
            {" "}
            <span className="font-bold">Color:</span> {color}
          </p>
          <p className="text-lg md:text-xl text-gray-800">
            <span className="font-bold">Picture Time:</span> {pictureTime}
          </p>
          <p className="text-lg md:text-xl text-gray-800">
            <span className="font-bold">Paid Reservation Deposit:</span>{" "}
            {reservation}
          </p>
        </div>

        <div className="min-h-screen">
          <div className="flex flex-col md:flex-row justify-between md:items-center"></div>
          {loading && (
            <img
              style={{ display: "block", margin: "auto" }}
              alt="loading image animation"
              src={LoadingImage}
            />
          )}
          {loading === false && !costume && (
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-ascendance text-4xl font-semibold mb-10">
                No Results Found
              </h2>
              <img
                className="w-96"
                style={{ display: "block", margin: "auto" }}
                alt="loading image animation"
                src={NoResults}
              />
            </div>
          )}
          {loading === false && error && (
            <div className="flex flex-col justify-center items-center">
              <h2 className="text-ascendance text-4xl font-semibold mb-10">
                We Encountered an error, please try again or call us at
                786-571-7778
              </h2>
              <img
                className="w-96"
                style={{ display: "block", margin: "auto" }}
                alt="loading image animation"
                src={NoResults}
              />
            </div>
          )}
          {paperformurl && (
            <div className="mb-6">
              <a
                className="bg-ascendance text-white py-2 px-3 rounded"
                href={paperformurl}
                target="_blank"
                rel="noreferrer"
              >
                Costume Acknowledgment
              </a>
            </div>
          )}
          {costume.length > 0 &&
            costume.map(costume => (
              <CostumeCard
                key={costume.id}
                costume={costume}
                setModalImage={setModalImage}
                setShowModal={setShowModal}
              />
            ))}
          <div className="bg-white rounded-lg shadow mb-5">
            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-tr-lg rounded-tl-lg ">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Important Dates
              </h3>
            </div>
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 p-3">
              <li className="col-span-1 flex flex-col justify-start text-center bg-white rounded-lg md:border md:border-gray-50 shadow-sm divide-y divide-gray-200">
                <div className="flex-1 flex flex-col p-3 md:p-3 lg:p-2">
                  <h4 className="text-ascendance font-semibold">
                    Payment Deadline
                  </h4>
                  <p className="text-sm text-gray-600">
                    Saturday, March 15th, 2025
                  </p>
                </div>
              </li>
              <li className="col-span-1 flex flex-col justify-start text-center bg-white rounded-lg md:border md:border-gray-50 shadow-sm divide-y divide-gray-200">
                <div className="flex-1 flex flex-col p-3 md:p-3 lg:p-2">
                  <h4 className="text-ascendance font-semibold">
                    Pictures (at the studio)
                  </h4>
                  <p>
                    {" "}
                    You will be assigned a session date on either Saturday or
                    Sunday.
                  </p>
                  <p className="text-sm text-gray-600">
                    Saturday, April 12th, 2025
                  </p>
                  <p className="text-sm text-gray-600">
                    Sunday, April 13th, 2025
                  </p>
                </div>
              </li>
              <li className="col-span-1 flex flex-col justify-start text-center bg-white rounded-lg md:border md:border-gray-50 shadow-sm divide-y divide-gray-200">
                <div className="flex-1 flex flex-col p-3 md:p-3 lg:p-2">
                  <h4 className="text-ascendance font-semibold">
                    Dress Rehearsal (at the theater)
                  </h4>
                  <p className="text-sm text-gray-600">May 31st, 2025</p>
                </div>
              </li>
              <li className="col-span-1 flex flex-col justify-start text-center bg-white rounded-lg md:border md:border-gray-50 shadow-sm divide-y divide-gray-200">
                <div className="flex-1 flex flex-col p-3 md:p-3 lg:p-2">
                  <h4 className="text-ascendance font-semibold">
                    Recital Shows
                  </h4>
                  <p className="text-sm text-gray-600">
                    Sunday, June 1st, 2025
                  </p>
                </div>
              </li>
            </ul>
            <p className="p-3 text-sm text-gray-600">
              Note: We already measured all students to determine their costume
              sizes.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Costumes
